import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'gatsby';
import { format } from 'date-fns';
import Link from '/src/components/LocalizedLink';
import Layout from '../components/Layout';

import { URL_KEYS_ROUTES_EN } from '../constants/routes';

import imgDots from '../assets/images/life-in-barcelona/Dots.svg';
import imgPhoto1 from '../assets/images/life-in-barcelona/Image2.jpg';
import iconTick from '../assets/images/svg/tick.svg';

import imgGrid1 from '../assets/images/life-in-barcelona/seventh.jpg';
import imgGrid2 from '../assets/images/life-in-barcelona/eighth.png';
import imgGrid3 from '../assets/images/life-in-barcelona/Photo 2 (2).png';
import imgGrid4 from '../assets/images/life-in-barcelona/sixth.jpg';
import imgGrid5 from '../assets/images/life-in-barcelona/Image3.jpeg';
import imgGrid6 from '../assets/images/life-in-barcelona/Image4.png';

import IconArrowRight from '../assets/images/svg/arrow-right.inline.svg';

import '../assets/styles/pages/cultural-activities.scss';

const formatDate = (dateString) => {
  return format(new Date(dateString), 'EEEE d MMMM yyyy');
};

const CulturalActivities = ({ data }) => {
  const culturalActivities =
    data?.allPrismicCulturalActivities?.edges?.[0]?.node?.data?.activity_card;

  return (
    <Layout
      title={'Fun Student Activities & Events at ILA Barcelona | 2025'}
      description={
        "Iberia Language Academy's offers a dynamic and enriching activities lineup for students. Find out more about our immersive Spanish language courses."
      }
    >
      <div className="life-in-barcelona-page">
        <section className="c-first-section c-first-section--md">
          <div className="container">
            <h1 className="title">Cultural Activities</h1>
            <p className="c-text-24 description">
              Immerse yourself in the fascinating culture of beautiful Barcelona
            </p>
          </div>
        </section>

        <section className="second-section c-section-py">
          <div className="container">
            <div className="row top">
              <div className="col-12 col-lg-6 col-md-7 order-md-first order-last">
                <div className="block_l">
                  {/* <h2 className="c-title-34 block__title">
                    <FormattedMessage id="barcelona.sec2.title" />
                  </h2> */}
                  <p className="c-text-18 block__subtitle">
                    Barcelona is not only famous for its stunning architecture and vibrant
                    atmosphere but also for its diverse range of cultural activities. Immerse
                    yourself in the local culture, make new friends, and enhance your language
                    skills while enjoying the city's unique offerings. Here are just a few exciting
                    cultural activities to experience during your time in Barcelona:
                  </p>
                  {/* <p className="c-text-16 block__description"> */}
                  <div className="c-benefit-list block_r__benefits">
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Language exchange and social meet ups</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Paella making workshops</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Costa Brava and beach trips</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Guided walking tours</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Yoga and wellness activities</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Ski trips</p>
                    </div>
                  </div>
                  {/* </p> */}
                  {/* <a
                    href={fileTEFLIberiaBrochure}
                    className="c-btn c-btn--red"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="homepage.sec6.downloadBrochure" />
                  </a> */}
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-5 order-md-last order-first">
                <div className="block_r">
                  <div className="block_r__img">
                    <img loading="lazy" src={imgPhoto1} alt="Barcelona" className="block_r__img" />
                  </div>

                  <div className=" block_r__trinity">
                    <img loading="lazy" src={imgDots} alt="iconTrinity" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="fourth-section">
          <div className="container">
            <div className="grid-list">
              <div className="grid-item">
                <img className="grid-item__img" src={imgGrid1} alt="imgGrid1" />
              </div>
              <div className="grid-item">
                <img className="grid-item__img" src={imgGrid2} alt="imgGrid1" />
              </div>
              <div className="grid-item">
                <img className="grid-item__img" src={imgGrid3} alt="imgGrid1" />
              </div>
              <div className="grid-item">
                <img className="grid-item__img" src={imgGrid4} alt="imgGrid1" />
              </div>
              <div className="grid-item">
                <img className="grid-item__img" src={imgGrid5} alt="imgGrid1" />
              </div>
              <div className="grid-item">
                <img className="grid-item__img" src={imgGrid6} alt="imgGrid1" />
              </div>
            </div>
            {/* <div className="col-md-6 col-12">
                <div className="block_l">
                  <h2 className="block_l__title c-title-34">
                    Ready to Experience Barcelona to the Fullest?
                  </h2>
                  <p className="block_l__description c-text-18">
                    Check out this month’s programme of cultural activities.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="block_r">
                  <img loading="lazy" src={imgPhoto2} alt="Barcelona" className="block_r__img" />
                  <img loading="lazy" src={imgPhoto3} alt="imgDots" className="secondary-img" />
                </div>
              </div> */}
          </div>
        </section>

        <section className="third-section c-section-py">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-12">
                <div className="block_l">
                  <h2 className="block_l__title c-title-34">
                    Ready to Experience Barcelona to the Fullest?
                  </h2>
                  <p className="block_l__description c-text-18">
                    Check out this month’s programme of cultural activities.
                  </p>
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="row top">
                {culturalActivities?.map(
                  ({
                    activity_date,
                    activity_description,
                    activity_image: { url },
                    activity_name,
                  }) => {
                    const formattedDate = formatDate(activity_date);
                    return (
                      <div className="col-lg-4">
                        <div className="item">
                          <img className="item__img" src={url} alt={activity_name.text} />
                          <p className="c-text-14">{formattedDate}</p>
                          <h2 className="item__title">{activity_name.text}</h2>
                          <p className="item__description">{activity_description.text}</p>
                          <FormattedMessage id={URL_KEYS_ROUTES_EN.CONTACT_US}>
                            {(locale) => (
                              <Link to={locale[0]} className="c-btn c-btn--red">
                                Contact Us
                                <IconArrowRight />
                              </Link>
                            )}
                          </FormattedMessage>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allPrismicCulturalActivities {
      edges {
        node {
          data {
            activity_card {
              activity_date
              activity_description {
                text
              }
              activity_image {
                url
              }
              activity_name {
                text
              }
            }
          }
        }
      }
    }
  }
`;

export default CulturalActivities;
